import { FaArrowRightLong } from "react-icons/fa6";
import Hoa1 from "../assets/flower/hoa1.jpg";
import Hoa2 from "../assets/flower/hoa2.jpg";
import Hoa3 from "../assets/flower/hoa3.jpg";
import Hoa4 from "../assets/flower/hoa4.jpg";
import Hoa5 from "../assets/flower/hoa5.jpg";
import Hoa6 from "../assets/flower/hoa6.jpg";
// import Hoa7 from "../assets/flower/hoa7";
// import Hoa8 from "../assets/flower/hoa8.jpg";
import Hoa9 from "../assets/flower/hoa9.jpg";
import Hoa10 from "../assets/flower/hoa10.jpg";
import Hoa11 from "../assets/flower/hoa11.jpg";
import Hoa12 from "../assets/flower/hoa12.jpg";
import Hoa13 from "../assets/flower/hoa13.jpg";
import Hoa14 from "../assets/flower/hoa14.jpg";
import Hoa15 from "../assets/flower/hoa15.jpg";
import Hoa16 from "../assets/flower/hoa16.jpg";
import Hoa17 from "../assets/flower/hoa17.jpg";
import Hoa18 from "../assets/flower/hoa18.jpg";
import Hoa19 from "../assets/flower/hoa19.jpg";
import Hoa20 from "../assets/flower/hoa20.jpg";
import Hoa21 from "../assets/flower/hoa21.jpg";
import Hoa22 from "../assets/flower/hoa22.jpg";
import Hoa23 from "../assets/flower/hoa23.jpg";
import Hoa24 from "../assets/flower/hoa24.jpg";
import Hoa25 from "../assets/flower/hoa25.jpg";
import Hoa26 from "../assets/flower/hoa26.jpg";
import Hoa27 from "../assets/flower/hoa27.jpg";
import Hoa28 from "../assets/flower/hoa28.jpg";
import Hoa29 from "../assets/flower/hoa29.jpg";
import Hoa30 from "../assets/flower/hoa30.jpg";
import Hoa31 from "../assets/flower/hoa31.jpg";
import Hoa32 from "../assets/flower/hoa32.jpg";
import Hoa33 from "../assets/flower/hoa33.jpg";
import Hoa34 from "../assets/flower/hoa34.jpg";
import Hoa35 from "../assets/flower/hoa35.jpg";
import Hoa36 from "../assets/flower/hoa36.jpg";
import Hoa37 from "../assets/flower/hoa37.jpg";
import Hoa38 from "../assets/flower/hoa38.jpg";
import Hoa39 from "../assets/flower/hoa39.jpg";
import Hoa40 from "../assets/flower/hoa40.jpg";
import Hoa41 from "../assets/flower/hoa41.jpg";
import Hoa42 from "../assets/flower/hoa42.jpg";
import Hoa43 from "../assets/flower/hoa43.jpg";
import Hoa44 from "../assets/flower/hoa44.jpg";
// import Hoa45 from "../assets/flower/hoa45.jpg";
// import Hoa46 from "../assets/flower/hoa46.jpg";
import Hoa47 from "../assets/flower/hoa47.jpg";
import Hoa48 from "../assets/flower/hoa48.jpg";
import Hoa49 from "../assets/flower/hoa49.jpg";
import Hoa50 from "../assets/flower/hoa50.jpg";
import Hoa51 from "../assets/flower/hoa51.jpg";
import Hoa52 from "../assets/flower/hoa52.jpg";
import Hoa53 from "../assets/flower/hoa53.jpg";
import Hoa54 from "../assets/flower/hoa54.jpg";
import Hoa55 from "../assets/flower/hoa55.jpg";

function Flower() {
    return (
        <div className="wrapper">
            <img
                src="https://images.unsplash.com/photo-1694730061170-c4c04a1e29b6?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Banner "
                className="visa__banner"
            />
            <div className="container">
                <div className="visa__title visa__line">HOA TƯƠI DINGDING</div>
                <div className="teambuilding__desc">
                    {/* <p className="teambuilding__desc-content">
                        DESC
                    </p> */}
                </div>
                <div className="flower">
                    <div className="flower__item">
                        <img
                            src={Hoa1}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                {/* <div className="flower__name">Hoa Hồng Có Gai</div> */}
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa2}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                {/* <div className="flower__name">Hoa Hồng Có Gai</div> */}
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        700.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa3}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                {/* <div className="flower__name">Hoa Hồng Có Gai</div> */}
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa4}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                {/* <div className="flower__name">Hoa Hồng Có Gai</div> */}
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa5}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.800.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa6}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa9}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa10}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa11}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa12}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        700.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa13}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa14}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa15}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.000.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa16}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa17}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa18}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        700.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa19}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa20}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        700.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa21}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa22}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        800.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa23}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.000.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa24}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa25}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa26}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa27}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa28}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa29}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa30}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        800.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa31}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        800.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa32}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa33}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        300.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa34}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        300.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa35}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        300.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa36}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa37}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        500.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa38}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa39}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa40}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa41}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa42}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa43}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa44}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa47}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa48}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">200.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa49}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    {/* <div className="flower__price">250.000đ</div> */}
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa50}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        250.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa51}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        250.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa52}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        200.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa53}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        800.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa54}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.000.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flower__item">
                        <img
                            src={Hoa55}
                            alt="flower"
                            className="flower__image"
                        />
                        <a
                            href="https://www.facebook.com/messages/t/1848020962149796"
                            target="_blank"
                        >
                            <div className="flower__info">
                                <div className="flower__bottom">
                                    <div className="flower__price">
                                        1.000.000đ
                                    </div>
                                    <div className="flower__contact">
                                        Liên Hệ <FaArrowRightLong />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Flower;
