const URL = "https://dingding.vn/Travel_API/api";

// const URL = "http://localhost/Travel_API/api";

export default {
    URL_getTourCard: URL + "/getTourCard.php",
    URL_getCategory: URL + "/getCategory.php",
    URL_getProductCategory: URL + "/getProductCategory.php",
    URL_getTourCategory: URL + "/getTourCategory.php",
    URL_getTourDetail: URL + "/getTourDetail.php",
    URL_getProductRelated: URL + "/getProductRelated.php",
    URL_searchProduct: URL + "/searchProduct.php",
    URL_getInformation: URL + "/getInformation.php",
    URL_getProductCategory: URL + "/getProductCategory.php",
    URL_updateCart: URL + "/updateCart.php",
    URL_deleteCart: URL + "/deleteCart.php",
    URL_getCart: URL + "/getCart.php",
    URL_setCart: URL + "/setCart.php",
    URL_setOrder: URL + "/setOrder.php",
    URL_setNewPass: URL + "/setNewPass.php",
    URL_setNewUser: URL + "/setNewUser.php",
    URL_setViewProduct: URL + "/setViewProduct.php",
    URL_getUser: URL + "/getUser.php",
    URL_getDetailOrder: URL + "/getDetailOrder.php",
    URL_getClientOrder: URL + "/getClientOrder.php",
    URL_getOrderByUser: URL + "/getOrderByUser.php",
    URL_getHomeAdmin: URL + "/getHomeAdmin.php",
    URL_getOrder: URL + "/getOrder.php",
    URL_getIdAdmin: URL + "/getIdAdmin.php",
    URL_getUserAdmin: URL + "/getUserAdmin.php",
    URL_deleteUser: URL + "/deleteUser.php",
    URL_editRole: URL + "/editRole.php",
    URL_deleteProduct: URL + "/deleteProduct.php",
    URL_deleteOrder: URL + "/deleteOrder.php",
    URL_editStatus: URL + "/editStatus.php",
    URL_addNewCategory: URL + "/addNewCategory.php",
    URL_deleteCategory: URL + "/deleteCategory.php",
    URL_editTour: URL + "/editTour.php",
    URL_getCategoryEdit: URL + "/getCategoryEdit.php",
    URL_editProduct: URL + "/editProduct.php",
    URL_getTopTour: URL + "/getTopTour.php",
    URL_getNewTour: URL + "/getNewTour.php",
    URL_getTourFieldTrip: URL + "/getTourFieldTrip.php",
    URL_getTour: URL + "/getTour.php",
    URL_setInfor: URL + "/setInfor.php",
    URL_deleteTour: URL + "/deleteTour.php",
    URL_addNewTour: URL + "/addNewTour.php",
    URL_getCategoryByID: URL + "/getCategoryByID.php",
    URL_getRating: URL + "/getRating.php",
    URL_addRating: URL + "/addRating.php",
    URL_getNews: URL + "/getNews.php",
    URL_getTravelTicket: URL + "/getTravelTicket.php",
    URL_getNewsDetail: URL + "/getNewsDetail.php",
    URL_getTravelTicketDetail: URL + "/getTravelTicketDetail.php",
    URL_editNews: URL + "/editNews.php",
    URL_deleteNews: URL + "/deleteNews.php",
    URL_addNews: URL + "/addNews.php",
    URL_addReview: URL + "/addReview.php",
    URL_getReview: URL + "/getReview.php",
    URL_getReviewDetail: URL + "/getReviewDetail.php",
    URL_getTravelTicket: URL + "/getTravelTicket.php",
    URL_editTravelTicket: URL + "/editTravelTicket.php",
    URL_deleteTravelTicket: URL + "/deleteTravelTicket.php",
    URL_addTravelTicket: URL + "/addTravelTicket.php",
};
